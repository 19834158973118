const MAX_ATTEMPTS = 10;
const RETRY_DELAY = 100;

export const loadABTastyExperiment = (options) => {
  const { experimentId } = options;
  return new Promise((resolve, reject) => {
    let retries = 0;

    const checkForExperiment = () => {
      const experiment =
        window.ABTasty && window.ABTasty.getTestsOnPage()[experimentId];
      if (experiment && experiment.variationID !== undefined) {
        resolve(experiment);
      } else {
        retries++;
        if (retries <= MAX_ATTEMPTS) {
          setTimeout(checkForExperiment, RETRY_DELAY);
        } else {
          reject(
            `Experiment "${experimentId}" not found after ${MAX_ATTEMPTS} retries.`
          );
        }
      }
    };

    checkForExperiment();
  });
};
