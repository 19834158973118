const setReferral = () => {
  const query = new URLSearchParams(window.location.search);
  if (query.has("utm_source") && query.has("gclid")) {
    localStorage.setItem("referralType", query.get("utm_source").toUpperCase());
    localStorage.setItem("referralCode", query.get("gclid"));
  } else if (query.has("awc")) {
    localStorage.setItem("referralType", "AWIN");
    localStorage.setItem("referralCode", query.get("awc"));
  } else if (query.has("rc")) {
    localStorage.setItem("referralType", "FRIEND");
    localStorage.setItem("referralCode", query.get("rc"));
  }
};

export default setReferral;
