import CookieWarning from "@mytutor/shared-react-web-components/lib/CookieWarning";
import { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

type CCProps = {
  heroPosition: number;
};

const CookieConsentAutoClose = (props: CCProps) => {
  const { heroPosition } = props;
  // create a state to store the scroll position
  const [scrollPosition, setScrollPosition] = useState(0);
  // create a state for isVisible to show/hide the StickyButton
  const [isVisible, setIsVisible] = useState(true);
  // listen to the scroll event
  useEffect(() => {
    const handleScroll = () => {
      const scrollPos = window.scrollY > 0 ? window.scrollY : 0;
      setScrollPosition(scrollPos);

      if (heroPosition && heroPosition > 0) {
        // check if current scroll position is lower than hero position
        if (scrollPos > heroPosition - 100) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      }
    };

    // add the event listener to the window
    window.addEventListener("scroll", handleScroll);

    // remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [heroPosition, scrollPosition]);
  const isMobile = useMediaQuery("(max-width:430px)");

  if (!isVisible && isMobile) {
    return null;
  }
  return (
    <div id="cookieConsent__container">
      <CookieWarning />
    </div>
  );
};

export default CookieConsentAutoClose;
