// copied from shared-react-web-components/src/components/SiteTracking/index.js
export const getGoogleAnalyticsClientId = (cookiesString: string) => {
  let clientId;
  if (cookiesString) {
    clientId = cookiesString
      .split(/;\s*/)
      .filter((cookie) => cookie.startsWith("_ga="))
      .map((cookie) => cookie.replace("_ga=", ""))
      .flatMap((cookie) => /\d{3,}\.\d{3,}/.exec(cookie))
      .filter((clientId) => clientId)
      .at(0);
  }
  return clientId ?? undefined;
};
