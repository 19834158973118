import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const StickyButton = styled(Button)(({ theme }) => ({
  position: "sticky",
  bottom: 0,
  zIndex: 900,
  height: "38px",
  boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.3)",

  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

type StickyCTAProps = {
  heroPosition: number;
};

const StickyCTA = (props: StickyCTAProps) => {
  const { heroPosition } = props;
  // create a state to store the scroll position
  const [scrollPosition, setScrollPosition] = useState(0);
  // create a state for isVisible to show/hide the StickyButton
  const [isVisible, setIsVisible] = useState(false);

  // listen to the scroll event
  useEffect(() => {
    const handleScroll = () => {
      const scrollPos = window.scrollY > 0 ? window.scrollY : 0;
      setScrollPosition(scrollPos);

      if (heroPosition && heroPosition > 0) {
        // check if current scroll position is lower than hero position
        if (scrollPos > heroPosition - 50) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    };

    // add the event listener to the window
    window.addEventListener("scroll", handleScroll);

    // remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [heroPosition, scrollPosition]);

  if (!isVisible) {
    return null;
  }
  return (
    <StickyButton
      href="/view-tutors/"
      color="primary"
      fullWidth={true}
      data-test="findATutorCTA"
    >
      Find a tutor
    </StickyButton>
  );
};

export default StickyCTA;
