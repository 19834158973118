import { useEffect, useState } from "react";
import { loadABTastyExperiment } from "../utils/loadABTastyExperiment";

const VARIANT_NAME = "Variation 1";

export const useIsOnboardingTestVariant = () => {
  const [experiment, setExperiment] = useState(null);
  const [isOnboardingTestVariant, setIsOnboardingTestVariant] = useState(false);

  useEffect(() => {
    loadABTastyExperiment({
      experimentId: process.env.NEXT_PUBLIC_ONBOARDING_TEST_ID,
    })
      .then((experiment) => {
        console.log("found experiment", experiment);
        setExperiment(experiment);
      })
      .catch((e) => {
        console.warn(e);
      });
  }, []);

  useEffect(() => {
    setIsOnboardingTestVariant(experiment?.variationName === VARIANT_NAME);
  }, [experiment]);

  return isOnboardingTestVariant;
};
